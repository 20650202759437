<template>
    <div>

        <v-row>
            <v-col>
                <v-radio-group
                v-model="values.category"
                mandatory
                label="Tipo de transaccíon"
                data-cy="category-input"
                >
                    <v-row >
                        <v-col cols="6" sm="3" class="py-2">
                            <radioItemForm
                                label="Depósito"
                                valueInput="Deposit"
                                title=""
                                data-cy="category-input-deposit-value"
                            >
                            </radioItemForm>
                            
                            
                        </v-col>
                        <v-col cols="6" sm="3" class="py-2">
                            <radioItemForm
                                label="Retiro"
                                valueInput="Withdraw"
                                title=""
                                data-cy="category-input-withdraw-value"
                            >
                            </radioItemForm>
                            
                            
                        </v-col>
                    </v-row>
                </v-radio-group>
            </v-col>
            
        </v-row>

         <v-row>
            <v-col  class="py-2">
                <datePickerForm
                    label="Fecha"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                    data-cy="date-input"
                ></datePickerForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <textFieldForm
                    @valueChange="(v) => {values.amount = v}" 
                    :valueInput="values.amount" 
                    :rule="rules.cost" 
                    label="Monto"
                    :errorMessages="serverErrors.amount"
                    :prefix="(values.amount == '' || values.amount == null ) ? '' : '$'"
                    data-cy="amount-input"
                ></textFieldForm>
            </v-col>
        </v-row>


       
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.description = v; delete serverErrors.description; delete serverErrors.slug}" 
                    :valueInput="values.description" 
                    :rule="[]" 
                    label="Descripción"
                    :errorMessages="serverErrors.description||serverErrors.slug"
                    data-cy="description-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <fileInputForm
                    
                    label="Comprobante"
                    :multiple="false"
                    :valueInput="values.receipt_file"
                    :url="values.receipt_url"
                    :rule="[]"
                    @valueChange="(v) => {values.receipt_file = v; delete serverErrors.receipt_file}"
                    :errorMessages="serverErrors.receipt_file"
                    v-on:update:url="values.receipt_url = $event"
                    :disableDelete="false"
                    data-cy="receipt_file-input"
                ></fileInputForm>
            </v-col>
        </v-row>
       

       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            loadingBanks: false,

            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                numbers: [
                    v => !!v || 'El campo es requerido',
                    v => /^[0-9]*$/.test(v) || 'El campo sólo debe contener números',
                    // v => (v.length == 0 || v.length == 10) || 'El campo debe contener 10 digitos'
                ],
                cost: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
                
            },

            serverItems:{
                banks: []
            }
        }
    },
   
    mounted(){
    },
    methods: {
        
    }
}
</script>

<style>

</style>